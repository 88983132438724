import React from "react"
import Sticky from "react-sticky-el"
import {graphql} from "gatsby";

import SEO from "../components/SEO/index"
import Contact from "../components/Sections/Contact"
import NavBar from "../components/navbar"
import localize from "../components/localize";

const ContactPage = ({data}) => {
    const contact = data.contacts.edges[0].node;
    const address = data.address.edges;

    return (
    <>
      <SEO title="Dryprod" description="Наши контакты" />
      <Sticky className="sticky-selector" topOffset={150}>
        <div className="is-shadow">
          <NavBar data={data.menu} />
        </div>
      </Sticky>
      <Contact contact={contact} address={address} />
    </>
  )
}

export default localize(ContactPage);

export const ContactPageQuery = graphql`
  query {
  menu: allSanityMenu {
      edges {
        node {
           name {
             _type
             en
             ru
           }
         }
       }
     } 
     address: allSanityAddress {
        edges {
          node {
            address {
              en
              ru
              _type
            }
          }
        }
      }
      contacts: allSanityContact {
        edges {
          node {
            phone {
              en
              ru
            }
            copyright {
              en
              ru
              _type
            }
            phone_numbers {
              _type
              en
              ru
            }
            social {
              _type
              en
              ru
            }
            textarea {
              _type
              en
              ru
            }
            our_contacts {
              _type
              en
              ru
            }
            name {
              _type
              en
              ru
            }
            address {
              _type
              en
              ru
            }
            button {
              _type
              en
              ru
            }
            copyright {
              _type
              en
              ru
            }
            email {
              _type
              en
              ru
            }
            email_title {
              _type
              en
              ru
            }
            leave_request {
              _type
              en
              ru
            }
          }
        }
      }
  }
`

